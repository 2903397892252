const TitleTip = ({ bgColor, text }) => (
  <div
    className="relative inline-block text-white rounded-[23px] md:px-8 py-2 px-5 lg:text-[24px]"
    style={{ backgroundColor: bgColor }}
  >
    {text}
    <div className="absolute bottom-[-20px] left-4 h-0 w-0 border-[13px] border-transparent border-t-[22px] border-t-[#29bcb7] translate-y-[4px] rounded-[2px] md:bottom-[-25px] md:left-7"></div>
  </div>
);

export default TitleTip;
