import { useRouter } from "next/router";
import TitleTip from "../common/Titletip";
import { logEvent } from "../Analytics/google-analytics";
import { ctaText, productCardDetails } from "../../lib/utils/constants";
import ProductCard from "./ProductCard";
import { trackClick } from "../../scripts/segment";
import { pageName } from "../../lib/utils/events_utils";
import Link from "next/link";
import Image from "next/image";
import { imageBasePath } from "lib/utils/imageUtils";
import withWindowDimensions from "../shared/withWindowDimensions";
import { pageRoutes } from "constant/routes";

export default withWindowDimensions(({ user, initLogin, isMobileSized }) => {
  const router = useRouter();

  return (
    <div className="mt-[70px] md:flex justify-between max-w-page-width mx-auto w-full">
      <div className="max-w-page-width mx-auto w-full px-6 md:self-center mt-6 md:mt-16 pt-3 md:pt-0 xl:px-0">
        <TitleTip text={<h1>No collateral required</h1>} bgColor="#29BCB7" />
        <div className="text-[#1b1d6a] text-[22px] pt-4 font-bold w-fit relative md:text-[40px] lg:text-[46px]">
          We finance your <br className="hidden md:block" />
          <div className="inline-block relative">
            <div className="relative">
              <div className="absolute bg-[url('/advance-website/assets/images/new-landing-page/stroke.svg')] bg-no-repeat w-[105px] h-[6px] right-[15px] bottom-0 left-auto md:left-0 md:bottom-[-5px] md:w-[218px] md:h-[13px] md:right-auto"></div>
              <span>study abroad</span>
            </div>
          </div>{" "}
          dreams
        </div>
        <div className="pt-4 s-laptop:pt-12 xl:pt-12 text-blue-950 text-sm s-laptop:text-xl md:text-2xl leading-tight ">
          {productCardDetails.map((product, idx) => (
            <ProductCard key={`${product?.title}-${idx}`} {...product} />
          ))}
        </div>
        <div className="flex justify-center flex-col md:block">
          <button
            onClick={() => {
              if (user) {
                router.push("/applications?clickId=36");
              } else {
                trackClick(pageName.HOME_PAGE, {
                  widgetName: "Hero",
                  widgetFormat: "Banner",
                  contentName: ctaText.GET_LOAN_OFFER,
                  contentFormat: "Button",
                });
                initLogin();
              }
            }}
            className="mb-2 py-3 md:py-4 rounded text-center font-bold text-md bg-primary text-white cursor-pointer px-24 md:px-32 hover:bg-primaryLighter whitespace-nowrap"
          >
            {user ? "Resume Application" : ctaText.GET_LOAN_OFFER}
          </button>
          <div>
            <div className="flex py-1 md:py-2">
              {[...Array(4)].map((e, i) => (
                <div className="mx-1" key={`review-star-${i}`}>
                  <Image
                    src={`${imageBasePath}/assets/icons/star-full.svg`}
                    alt="Review star"
                    width={22}
                    height={22}
                    className="max-w-full h-auto"
                  />
                </div>
              ))}
              <div className="mx-1">
                <Image
                  src={`${imageBasePath}/assets/icons/star-half.svg`}
                  alt="Review star"
                  width={14}
                  height={22}
                  className="max-w-full h-auto"
                />
              </div>
            </div>
            <p className="text-gray-1110 text-xs md:text-sm mx-1 p-px">
              We are rated <span className="font-bold">4.6</span> out of{" "}
              <span className="font-bold">5</span>
            </p>
            <Link
              href={pageRoutes.INSCHOOL_REVIEWS}
              className="text-primary text-sm md:text-base underline mx-1 py-px"
              onClick={() => {
                trackClick(pageName.HOME_PAGE, {
                  widgetName: "Hero",
                  widgetFormat: "Link",
                  contentName: "See What our students have to say",
                  contentFormat: "Link",
                });
                logEvent("LF-Home", "Testimonials", "Hero");
              }}
            >
              See what our students have to say...
            </Link>
          </div>
        </div>
      </div>
      <div className="mx-auto w-full px-4 md:self-center mt-6 md:mt-16 pt-3 md:pt-0 flex justify-center items-center">
        <Image
          src={
            isMobileSized
              ? "https://ik.imagekit.io/onsnhxjshmp/Leap_Finance/brand-mobile_8TVBpMoBa.png"
              : "https://ik.imagekit.io/onsnhxjshmp/Leap_Finance/brand-web_qVt_vkvLVc.png"
          }
          width={isMobileSized ? 332 : 649}
          height={isMobileSized ? 300 : 598}
          alt="Brand Image"
          priority
        />
      </div>
    </div>
  );
});
